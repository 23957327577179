<template>
    <div>
        <img class="rounded leftimage img-small" src="@/assets/news-network/large/tower-with-shard.jpg" />
        <div class="text">
            <b>By Jerry Meyer, Lead Concept Artist</b><br><br>We asked the NetDevil artists to tell us
            about their designs. Here's what they had to say:<br><br><b>What's happening in the image?</b><br><br><i>A
                shard/spike came from the sky and passed completely through the large tower in the middle. It is not
                known where the spike itself has landed at this point. There is a giant water shield up on the wall that
                has been breached and is now pouring water off the side of the wall.</i><br><br><b>Is there anything
                else you’d like us to know?</b> <br><br><i>This is a loosely-drawn image from a series of
                storyboards.</i><br><br>
        </div>
    </div>
</template>